import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import { Service } from './Service';
import { booxiMerchantKey } from '../../config/booxiApiConfig';
import { PaginatedResponse } from '../PaginatedResponse';

export interface ServiceRepositorySpec {
    getServices(): Promise<PaginatedResponse<Service[]>>;
}

export class ServiceRepository implements ServiceRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getServices() {
        const servicesResponse = await this.datasource.remote.getData(
            '/service',
            booxiMerchantKey(process.env.REACT_APP_API_KEY_MERCHANT_VALUE ?? '')
        );
        console.log('Servicesresponse: ', servicesResponse);
        return (servicesResponse.data as PaginatedResponse<Service[]>) || [];
    }
}
