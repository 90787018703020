import { ApiDatasource, ApiDatasourceSpec } from '../../io/datasources/ApiDatasource';
import { Category } from './Category';
import { PaginatedResponse } from './../PaginatedResponse';
import { booxiMerchantKey  } from '../../config/booxiApiConfig';


export interface CategoryRepositorySpec {
    getCategories(merchantKeys : string[]): Promise<PaginatedResponse<Category[]>>;
}

export class CategoryRepository implements CategoryRepositorySpec {
    constructor(public datasource: ApiDatasourceSpec = new ApiDatasource()) {}

    async getCategories(merchantKeys : string[]) {
        const categoriesResponse = (await this.datasource.remote.getData('/serviceCategory', booxiMerchantKey(merchantKeys[0] ?? '')));
        return categoriesResponse.data as PaginatedResponse<Category[]> || [];
    }
}
